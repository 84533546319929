import styled from 'styled-components';

export const Holder = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 150px;
    margin-right: 15px;
    object-fit: contain;
  }

  div.selectHolder {
    flex: 1 1;
    label {
      color: var(--main-bg);
      font-size: 18px;
    }
    div.selectHolder {
      width: 100%;
      border: 1px solid var(--dark-grey);
      border-radius: 30px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      height: 50px;
      margin-top: 10px;
    }
  }
`;
