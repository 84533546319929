import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import Select from 'components/Select';
import api from 'services/api';
import { Holder } from './styles';

interface SelectProps {
  value: string;
  label: string;
  logoUrl?: string;
}

interface Props {
  selectedColegioId?: string;
  setSelectedColegioId: React.Dispatch<SetStateAction<string | undefined>>;
}

const ColegioSelectionHolder = (props: Props) => {
  const { selectedColegioId, setSelectedColegioId } = props;

  const [selectedColegio, setSelectedCol] = useState<SelectProps>();
  const [colegios, setColegios] = useState<SelectProps[]>([]);

  const selectionChange = (value: any) => {
    setSelectedCol(value);
    setSelectedColegioId(value.value);
  };

  const getAllColegios = useCallback(async () => {
    api.get('/colegios').then(res => {
      const { data: initialData } = res;

      const data: SelectProps[] = [];

      for (let i = 0; i < initialData.length; i++) {
        const colegio = initialData[i];

        data.push({
          label: colegio.name,
          value: colegio.id,
          logoUrl: colegio.logo && colegio.logo.url ? colegio.logo.url : null,
        });
      }

      setColegios(data);
    });
  }, []);

  const haveColegioId = useCallback(() => {
    if (colegios && colegios.length !== 0) {
      const foundColegio = colegios.find(
        colegio => colegio.value === selectedColegioId
      );
      setSelectedCol(foundColegio);
    }
  }, [selectedColegioId, colegios]);

  useEffect(() => {
    getAllColegios();
  }, [getAllColegios]);

  useEffect(() => {
    haveColegioId();
  }, [haveColegioId]);

  return (
    <>
      <Holder>
        {selectedColegio && selectedColegio.logoUrl ? (
          <img
            src={selectedColegio && selectedColegio.logoUrl}
            alt={selectedColegio && selectedColegio.label}
          />
        ) : (
          <></>
        )}

        <div className="selectHolder">
          <label htmlFor="colegio">Colégio/escola:</label>
          <Select
            value={selectedColegio}
            options={colegios}
            name="colegio"
            id="colegio"
            onChange={selectionChange}
          />
        </div>
      </Holder>
    </>
  );
};

export default ColegioSelectionHolder;
