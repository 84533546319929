import React, { useCallback, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { navigate, PageProps } from 'gatsby';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Layout from 'components/_layout';
import ColegioHolder from 'components/ColegioSelectionHolder';
import {
  ListasHolder,
  Holder,
  SelectionHolder,
} from 'styles/pages/listas-escolares';
import Button from 'components/Button';
import Select from 'components/Select';
import api from 'services/api';
import { toast } from 'react-toastify';

interface DataForm {
  colegio: string;
  etapa: string;
  serie: string;
}

interface SelectProps {
  value: string;
  label: string;
  serie?: SelectProps[];
}

const ListaEscolares = (props: PageProps) => {
  const { location } = props;
  const formRef = useRef<FormHandles>(null);

  const [selectedColegioId, setSelectedColegioId] = useState<
    string | any | undefined
  >(undefined);
  const [fullEtapas, setFullEtapas] = useState<SelectProps[] | undefined>(
    undefined
  );
  const [selectedEtapa, setSelectedEtapa] = useState<SelectProps | undefined>(
    undefined
  );
  const [selectedSerie, setSelectedSerie] = useState<SelectProps | undefined>(
    undefined
  );

  const onSubmit = (data: DataForm) => {
    navigate(`/livros${data.serie ? `?serie=${data.serie}` : ''}`);
  };

  const getAllInfo = useCallback(async () => {
    if (selectedColegioId === undefined) {
      return;
    }
    api.get(`/colegios/colegio-full/${selectedColegioId}`).then(res => {
      const { data: initialData } = res;

      const data: SelectProps[] = [];

      for (let i = 0; i < initialData.etapa.length; i++) {
        const etapa = initialData.etapa[i];

        const serieTemp: SelectProps[] = [];

        for (let h = 0; h < etapa.serie.length; h++) {
          const serie = etapa.serie[h];

          serieTemp.push({
            value: serie.id,
            label: serie.name,
          });
        }

        data.push({
          value: etapa.id,
          label: etapa.name,
          serie: serieTemp,
        });
      }

      setFullEtapas(data);
    });
  }, [selectedColegioId]);

  const getQueryColege = useCallback(() => {
    const queryParams = queryString.parse(location.search);

    const { colegio } = queryParams;

    if (colegio) {
      setSelectedColegioId(colegio);
    }
  }, [location.search]);

  useEffect(() => {
    getAllInfo();
  }, [getAllInfo]);

  useEffect(() => {
    getQueryColege();
  }, []);

  useEffect(() => {}, [toast]);

  const selectionEtapaChange = (value: any) => {
    setSelectedEtapa(value);
  };

  const selectionSerieChange = (value: any) => {
    setSelectedSerie(value);
  };

  return (
    <Layout pathname={location.pathname}>
      <ListasHolder>
        <h1>Listas Escolares</h1>
        <h2>
          Selecione abaixo as informações referentes ao colégio (escola), a
          etapa e a série do aluno para buscar os livros referentes a lista
          escolar.
        </h2>
        <Holder>
          <Form ref={formRef} onSubmit={onSubmit}>
            <ColegioHolder
              selectedColegioId={selectedColegioId}
              setSelectedColegioId={setSelectedColegioId}
            />
            {fullEtapas ? (
              <SelectionHolder>
                <label htmlFor="etapa">Etapa:</label>
                <Select
                  onChange={selectionEtapaChange}
                  options={fullEtapas}
                  name="etapa"
                  id="etapa"
                />
              </SelectionHolder>
            ) : (
              <></>
            )}
            {selectedEtapa && selectedEtapa.serie ? (
              <SelectionHolder>
                <label htmlFor="serie">Série:</label>
                <Select
                  options={selectedEtapa.serie}
                  onChange={selectionSerieChange}
                  name="serie"
                  id="serie"
                />
              </SelectionHolder>
            ) : (
              <></>
            )}
            {selectedSerie ? (
              <Button type="submit">Buscar Livros</Button>
            ) : (
              <></>
            )}
          </Form>
        </Holder>
      </ListasHolder>
    </Layout>
  );
};

export default ListaEscolares;
