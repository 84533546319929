import styled from 'styled-components';

export const ListasHolder = styled.div`
  width: 70%;
  height: auto;

  margin: 40px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  h1 {
    color: var(--main-bg);
    font-size: 38px;
    font-weight: 600;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  h2 {
    color: var(--dark-gray);
    font-size: 18px;
    text-align: center;
    margin: 5px 20px 20px;
  }

  @media (max-width: 768px) {
    & {
      width: 95%;
    }
  }
`;

export const Holder = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

  padding: 50px;

  form {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    button {
      margin-top: 60px;
      padding: 15px 30px;
      border-radius: 30px;
    }
  }

  @media (max-width: 768px) {
    & {
      padding: 30px;
    }
  }
`;

export const ColegioHolder = styled.div`
  width: 100%;
  height: auto;

  background-color: #fff;
  border-radius: 60px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
`;

export const SelectionHolder = styled.div`
  margin-top: 40px;

  width: 100%;
  height: auto;

  flex: 1 1;

  label {
    color: var(--main-bg);
    font-size: 18px;
  }

  div.selectHolder {
    width: 100%;
    border: 1px solid var(--dark-grey);
    border-radius: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    height: 50px;
    margin-top: 10px;
  }
`;
